
 @import 'variable';
  @import 'spinner';
  @import 'app';
  @import 'header';
 @import 'pages';
 @import 'sidebar';
 @import 'responsive';
 
 @import "../styles/icons/material-design-iconic-font/css/materialdesignicons.min.css";
 
body{
	margin: 0;
  font-family: 'Plus Jakarta Sans', sans-serif;

	font-size: 22px;
	color:#4b616f;
	font-weight: 400;
}
*,:focus{
	outline: none;
}
*{
	margin: 0;
	padding: 0;
}
p,h1,h2,h3,h4,h5,h6,ul,li{
	margin: 0;
	padding: 0;
}

a:hover{
	text-decoration: none;
}

.success {
    background: #4e585f !important;
    color:white;
  }
  .failure {
    background: #4e585f !important;
    color: white;
  }

  .mat-snack-bar-container {
    // margin: 74px !important; 
    margin-top: 68px;
    margin-right: 36px;
  }

  .mat-drawer-inner-container {
    color:#ffffff;
    //  background-color: $dark-blue;
  }


  .cropper {
    position: fixed;
    width: 100%;
    height:100%;
    top: 0px;
    left: 0px;
    background: #fff;
    z-index: 9999;
    text-align: center;

    .cropper-title {
        margin-top: 20px;
        margin-bottom: 5px;
    }
}

.total_label {
  font-size: 12px;
  color: #777777;
}
._left_margin {
  margin: 0;
  padding: 0;
}


.full_name {
  font-size: 24px;
  color: #000000;
  font-weight: 500;
}
.tag_Line {
  color: #333333;
  font-weight: 400;
  font-size: 16px;
}
.desciption_bio {
  color: #777777;
  font-weight: 400;
  font-size: 16px;
  height: 24px;
  overflow: hidden;

}
.img_lock {
  width: 26px;
  line-height: 0;
  align-content: space-around;
  margin-bottom: 4px;
}
.class_login_text {
  margin-right: 41px;
}
#myBtn {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #e3bcb8;
  color: white;
  cursor: pointer;
  padding: 15px;
  height: 50px;
  width: 50px;
  border-radius: 24px;
}
#mainDIV {
  scroll-behavior: smooth;
}
img.scroll_img {
  width: 20px;
  height: 20px;
  margin-top: -10px;
}
._modal_text {
  text-align: center;
}
.clinicIcon{
  height: 35px;
  cursor: pointer;
}
.custom-swal-popup{
  width: 250px !important;
}
.custom-swal-confirm-button{
  font-size: 10px !important;
}
.breadcom_top_text {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  cursor: pointer;
}
.breadcom_top_active {
  font-size: 14px;
  font-weight: 400;
  color: #00C4C3;
}
.breadcom_seprate {
  border-left: 1.5px solid #000;
  margin-left: 5px;
  margin-right: 5px;
}
.breadcom-inner span {
  font-weight: 700;
  color: #000000;
  font-size: 24px;

}
.searchInput{
  border: none;
  border-radius: 18px;
  position: relative;
  padding-right: 65px;
  color: #808080;
  font-weight: 500;
  height: 50px;
}

.search_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  top: 25px;
  right: 5px;
  border-left: 2px solid #E0E0E0;
  padding-left: 10px;
  padding-right: 10px;
}
.filter_main{
  display: flex;
  gap: 20px;
  justify-content: end;
}
.sort {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 18px;
  height: 50px;
  color: #808080;
  font-weight: 500;
  cursor: pointer;
}
.searchbox {
  display: flex;
  align-items: center;
  position: relative;
}

.selectt {
  appearance: none; /* Remove default arrow in most browsers */
  -webkit-appearance: none; /* Remove default arrow in Safari */
  -moz-appearance: none; /* Remove default arrow in Firefox */
  background-image: url('../img/arrow_down.svg'); /* Path to your custom icon */
  background-repeat: no-repeat;
  background-position: right 10px center; /* Adjust the position as needed */
  padding-right: 30px; /* Add space for the custom icon */
  width: 67px;
  height: 42px;
}

.selectt::-ms-expand {
  display: none; /* Remove default arrow in Internet Explorer */
}

/* Optional: Style the select box for better appearance */
.selectt {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  color: #333;
}

