$text-bg: #FFFFFF;
$login_type_bg: white;
$btn_bg_cl:#8897A9;
$btn_cl:white;
$input_cl:#EEEEEE;
$login_right_cl:white;
$themeCL:#8897A9;
$sub_btn_hovar:#8897A9;

/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400;700;900&display=swap');

$bodyfont: 'Lato', sans-serif;
$headingfont: 'Lato', sans-serif;

@import '~@angular/material/theming';

/*Material Theme Colors*/

$primary: mat-palette($mat-blue, 600);
$accent: mat-palette($mat-indigo, 500);
$warn: mat-palette($mat-pink, 500);
$theme: mat-light-theme($primary, $accent, $warn);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

@include mat-core();

$custom-typography: mat-typography-config(
  $font-family: 'Lato, sans-serif'
);

// Override the typography in the core CSS.
@include mat-core($custom-typography);

// Default Theme
@include angular-material-theme($theme);
$topbar: #2196f3;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt: #f2f6f8;
$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;
$full_page_background:#f1f1f1;    /* background color of full page content */

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$primary: #7460ee;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

$border: rgba(120, 130, 140, 0.13);



$themecolor:#00C4C3;
$dark-blue:#00C4C3;
$darkTheme:#00C4C3;