
@import "./assets/styles/variable.scss";

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-text-fill-color: #3b5362 !important;
}
html,body{
	height: 100%;
	scroll-behavior: smooth;
  font-family: 'Plus Jakarta Sans', sans-serif;
}
:focus {
    outline: 0 !important;
}
.hasError, .has-error {
  position: relative;
}
// PAGINATION //
.pagination_custom li {
  list-style: none;
 text-decoration: none;
 display: inline-block;
 padding: 10px;
 background-color: #FFFFFF;
 margin: 5px;
 color: #000;
 border-radius: 18px;
 font-size: 22px !important;
 cursor: pointer;
 border: 1px solid #E6EAF0;
 font-size: 16px;
}
li.show_number {
  background-color: #FF5C38 !important;
  border-radius: 18px;
  font-size: 14px !important;
  color: #fff !important;
  border: none !important;
}
.pagination_custom ul {
  text-decoration: none;
  list-style: none;
}
.pagination_custom {
  float: right;
  font-size: 14px;
}
.disabled_pagination {
  background-color: #d7f8f7 !important;
  pointer-events: none;
  cursor: not-allowed;
}
@media(max-width:768px ){
  .btn {
      padding:9px;
      font-size: 13px;
  }
  .pagination_custom li {
      padding:7px
  }
}
::-webkit-scrollbar {
  height: 3px;
}
::-webkit-scrollbar-thumb {
  background: $dark-blue !important ;
  border-radius: 10px;
}
  
.tablename{
  border-radius: 5px;
border: 1.42532px solid rgba(237,244,255, 1);
background-color: $dark-blue;
padding: 8px 14px;
margin-left: -25px;
font-size: 15px!important;
display: table;
    margin-top: -2px;
	color: #000000;
	font-weight: bold;
}
.addButton{
border-radius: 7px;
border: 1.42532px solid rgba(237,244,255, 1);
background-color:#ff3ea5;
color: #fff;
font-size: 12px !important;
border: 2px solid $dark-blue;
i{
font-size: 9px;
}
}
.addButton:hover{
  color: #000;;
  background-color: white;
  border: 2px solid $dark-blue;
}
.tableheading{
color: #313131;
font-size: 14px;
font-weight: 500;
background-color: #FFFFFF;
}
.search-input-container {
position: relative;
}

.search-icon {
position: absolute;
top: 50%;
left: 12px; /* Adjust this value to control the icon's horizontal position */
transform: translateY(-50%);
}
.forSearchBar{
position: relative;

}
.searchIcon{
position: absolute;
bottom: 20px;
right: 0px;
background: white;
padding: 0px 5px;
color: #777777;
}
.table_outer {
margin: 0px 10px;
}

.card-header {
color:#FFFFFF;
font-weight: 500;
background-color: transparent;
border-bottom: none;
padding-bottom: 0;
p {
    font-size: 15px;
    margin-bottom:0;
}
h4 {
   font-size: 20px;
}
}

.card-title, .text-left{
font-size: 20px;
color:#000000;
font-weight:700;
}


.card-body {
padding: .9375rem 20px;
position: relative;
}
.table thead th {
border-bottom:none!important;
border-top:none;
}

.table>tbody>tr>td {

font-size: 14px;
font-weight: 400;
}

._noWrap{
  white-space: nowrap;
}
.errorinput {
  font-size: 10px;
  position: absolute;
  color: red;
}
.errorinput:first-letter {
    text-transform: capitalize;
}
:disabled {
  opacity:.65;
}
.editImg{
width: 20px;
height: 20px;
cursor: pointer;
}
.editPlan{
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.inputFields{
  background: #EEEEEE;
  border-color: #DDDDDD;
}
.inputLabel{
  font-size: 16px;
  font-weight: 400;
}
.featureHead{
  font-size: 18px;
  font-weight: 700;
}
.submitBtn{
  background-color: $dark-blue;
  color:white;
  border-radius: 7px;
  
}
.submitBtn:hover{
  color: white;
}
.featureCard{
  width: 98%;
}
.cardBetween{
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.2);
}
.featureHeading{
 color: $dark-blue;
 font-weight: 700;
 font-size: 18px;
}
.tableData{
  overflow-y: auto;
  max-height: 200px;
}
.tableheading{
  position: sticky;
  top: 0;
  left: 0;
}
.addCandidateBtn {
  background-color: $darkTheme;
  color:white;
  box-shadow: 0 3px 10px 0px rgba(162, 227, 184, 0.3);
}
.loginBtn{
  background-color: #01c4c3;
  color: #fff;
  border-radius: 14px;
  font-size: 16px;
  padding: 10px;
}

// .loginBtn:disabled {
//   opacity: 3.65 !important;
// }

.addCandidateBtn:hover {
  color:white;
}
.custom-file label{
  background-color: white;
  border-radius: 69px;
  width: 70px;
  height: 70px;
  padding-top: 16px;
  cursor: pointer;
  margin-top: 16px;
  margin-right: 10px;
  transform: translate(119%, 128%);
}
.profile_pic{
  height: 160px;
  width: 170px;
  border: 3px solid white;
  border-radius: 50px;
  position: absolute;
  left: 0;
  right: 0;
}


// new
i {
	margin-right: 10px;
}
/*----------bootstrap-navbar-css------------*/
.navbar-logo{
	padding: 15px;
	color: #fff;
}
.navbar-mainbg{
  border-radius: 5px;
	padding: 0px;
  color: #000000;
}
#navbarSupportedContent{
	overflow: hidden;
	position: relative;
}
#navbarSupportedContent ul{
	padding: 0px;
	margin: 0px;
}
#navbarSupportedContent ul li a i{
	margin-right: 10px;
}
#navbarSupportedContent li {
	list-style-type: none;
	float: right;
	line-height: 0.0;
}
#navbarSupportedContent ul li a{
	color: #000000;
    text-decoration: none;
    font-size: 15px;
    display: block;
    padding: 20px 20px;
    transition-duration:0.6s;
	transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    position: relative;
}
#navbarSupportedContent>ul>li>a.active{
	color: #000000;
	background-color: transparent;
	transition: all 0.7s;
  border-bottom: 2px solid $darkTheme;
}
#navbarSupportedContent a:not(:only-child):after {
	content: "\f105";
	position: absolute;
	right: 20px;
	top: 10px;
	font-size: 14px;
	font-family: "Font Awesome 5 Free";
	display: inline-block;
	padding-right: 3px;
	vertical-align: middle;
	font-weight: 900;
	transition: 0.5s;
}
#navbarSupportedContent .active>a:not(:only-child):after {
	transform: rotate(90deg);
}
.hori-selector{
	display:inline-block;
	position:absolute;
	height: 100%;
	top: 0px;
	left: 0px;
	transition-duration:0.6s;
	transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
	background-color: #fff;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	margin-top: 10px;
}
.hori-selector .right,
.hori-selector .left{
	position: absolute;
	width: 25px;
	height: 25px;
	background-color: #fff;
	bottom: 10px;
}
.hori-selector .right{
	right: -25px;
}
.hori-selector .left{
	left: -25px;
}
.hori-selector .right:before,
.hori-selector .left:before{
	content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #5161ce;
}
.hori-selector .right:before{
	bottom: 0;
    right: -25px;
}
.hori-selector .left:before{
	bottom: 0;
    left: -25px;
}


@media(min-width: 992px){
	.navbar-expand-custom {
	    -ms-flex-flow: row nowrap;
	    flex-flow: row nowrap;
	    -ms-flex-pack: start;
	    justify-content: flex-start;
	}
	.navbar-expand-custom .navbar-nav {
	    -ms-flex-direction: row;
	    flex-direction: row;
	}
	.navbar-expand-custom .navbar-toggler {
	    display: none;
	}
	.navbar-expand-custom .navbar-collapse {
	    display: -ms-flexbox!important;
	    display: flex!important;
	    -ms-flex-preferred-size: auto;
	    flex-basis: auto;
	}
}


@media (max-width: 991px){
	#navbarSupportedContent ul li a{
		padding: 12px 30px;
	}
	.hori-selector{
		margin-top: 0px;
		margin-left: 10px;
		border-radius: 0;
		border-top-left-radius: 25px;
		border-bottom-left-radius: 25px;
	}
	.hori-selector .left,
	.hori-selector .right{
		right: 10px;
	}
	.hori-selector .left{
		top: -25px;
		left: auto;
	}
	.hori-selector .right{
		bottom: -25px;
	}
	.hori-selector .left:before{
		left: -25px;
		top: -25px;
	}
	.hori-selector .right:before{
		bottom: -25px;
		left: -25px;
	}
}
.mat-form-field{
  width: 100%;
}
.hyperLink{
  color: $darkTheme;
  text-decoration: underline;
  cursor: pointer;
}
.modal-title{
  font-size: 15px !important;
    font-weight: 600 !important;
}
._custom_navi {
    position: absolute;
    top: 0px;
    left: 27px;
}
._cursor {
	cursor: pointer;
}
.addBtn1{
	background-color: #00C4C3;
	color: white;
    white-space: nowrap;
    border-radius: 30px;
}
.addBtn{
	background-color: #00C4C3;
	color: white;
  border-radius: 30px;
}
.addBt {
    margin-left: -1px;
    margin-top: 2px;
}
.text-error{
	margin-top: 13px;
  }
  .btn_reset{
    color: #fff;
    background: #ff0f0f;
    font-size: 12px;
    margin-top: 8px;
  }
  .upload-options {
    position: relative;
    height: 55px;
    background-color: #777;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    top:-50px;
    transition: background-color ease-in-out 150ms;
    &:hover {
      background-color: lighten(#777, 10%);
    }
    & input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    & label {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
      &::after {
        content: 'add'; 
        font-family: 'Material Icons';
        position: absolute;
        font-size: 2.5rem;
        color: rgba(230, 230, 230, 1);
        left: calc(50% - 1.25rem);
        z-index: 0;
      }
      & span {
        display: inline-block;
        width: 50%;
        height: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;
        text-align: center;
        &:hover i.material-icons {
          color: lightgray;        
        }
      }
    }
  }
  
  
  .js--image-preview {
    height: 155px;
    width: 100%;
    position: relative;
    overflow: hidden;
  
    background-color: white;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  
  
  }
  .box {
    display: block;
    min-width: 300px;
    max-width: 300px;
    height: 160px;
  
    margin: 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    overflow: hidden;
  }
  .wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .flexCol{
    flex-direction: column
  }
  .productImage{
    padding-top: 3px;
    width: 100%;
    object-fit: contain;
    }
    .cropper .source-image{
      max-width: 88vw !important;
      max-height: 88vh !important;
    }
    .error{
      color: red;
    }

    // .mat-datepicker-popup{
    //   top: 248px ;
    //   right: 390px ;
    // }

.healthUserProfile{
  height: 40px;
  width: 40px;
  border-radius: 25%;
  object-fit: cover;
}

.rejectReason{
  height: 50px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
}
.activeGreen{
  border: 1px solid
  rgba(0, 196, 195, 1);
  border-radius: 25px;

  display: block;
  padding: 3px;
 cursor: pointer;
//  rgba(0, 196, 195, 1);
  font-size: 15px;
  text-align: center;
  background: rgba(224, 244, 243, 1);}

.inactiveRed{
  border: 1px solid #FF5C38;
  border-radius: 25px;
  cursor: pointer;
  display: block;
  padding: 3px;
  color: #FF5C38;
  font-size: 15px;
  text-align: center;
  background: #FAEAE6;
}
.inactiveDelete{
  border: 1px solid #FF0000;
  border-radius: 25px;
  cursor: pointer;
  display: block;
  padding: 3px;
  color: #FF0000;
  font-size: 15px;
  text-align: center;
  background: #FAE6E6;
}



.pendingStatus{
  border: 1px solid #01c4c3;
  border-radius: 25px;

  display: block;
  cursor: pointer;
  padding: 3px;
  color: #01c4c3;
  font-size: 15px;
  text-align: center;
  background: #e0f4f3;
}


.inactive{
  border: 1px solid #0096CC;
  border-radius: 25px;
  display: block;
  cursor: pointer;
  padding: 3px;
  color: #0096CC;
  font-size: 15px;
  text-align: center;
  background: #F5F9FF;
}
.mat-icon-button{
  top: 18px !important;
}

.tableheading{
  color: #808080;
}
.setAlign{
  text-align: center;
}
.setleft{
  text-align: center;
}

.custom-radio-group {
  display: flex !important; 
  flex-direction: row !important;
}
.custom-radio-group .mat-radio-button {
  display: flex;
  align-items: center;
  padding: 5px; /* Add padding to ensure border is not too tight */
  border-bottom: 1px solid #E6EAF0;
  width: 100%;
}
.custom-radio-group .mat-radio-button .mat-radio-outer-circle {
  border-color: #00C4C3 !important;
}

.custom-radio-group .mat-radio-button .mat-radio-inner-circle {
  background-color: #00C4C3 !important;
}

.custom-radio-group .mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
  border-color: #00C4C3 !important;
}

.custom-radio-group .mat-radio-button.mat-radio-checked .mat-radio-inner-circle {
  background-color: #00C4C3 !important;
}

.custom-radio-group {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.example-radio-button {
  margin: 5px;
}
.sortData {
  background-color: #fff;
  padding: 10px;
  position: absolute;
  top: 61px;
  right: 72px;
  border-radius: 10px;
  z-index: 99;
  // border: 1px solid #E6EAF0;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
}
.sortFilter {
  background-color: #fff;
  padding: 10px;
  position: absolute;
  top: 61px;
  border-radius: 10px;
  z-index: 99;
  // border: 1px solid #E6EAF0;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
}
.StatusFilter {
  background-color: #fff;
    padding: 10px;
    position: absolute;
    top: 37px;
    border-radius: 10px;
    z-index: 99;
    box-shadow: 2px 3px 17px rgba(0, 0, 0, 0.1);
}
.active_filter {
  color:  #00C4C3 !important;
}
.clear_filter {
  color: red;
  cursor: pointer;
  text-align: center;
  margin-top: 15px !important;
}
ul.showFilterBy {
  list-style: none;
}
ul.showFilterBy li {
  padding: 5px; /* Add padding to ensure border is not too tight */
  border-bottom: 1px solid #E6EAF0;
  cursor: pointer;
}
ul.showStatus {
  list-style: none;
}
ul.showStatus li {
  padding: 5px; /* Add padding to ensure border is not too tight */
  border-bottom: 1px solid #E6EAF0;
  cursor: pointer !important;
}
.mainFilterss{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  justify-content: end;
  gap: 20px;
}
.firstFilter{
  width: 40%;
}
.fixed_table {
  height: 65vh;
  overflow: auto !important;
}
@media(max-width: 448px){
  .firstFilter{
    width: 100%;
  }
  .mainFilterss{
    flex-direction: column;
  }
.form-control-select1{
  border-radius: 18px !important;
    padding: 8px;
    border: 1px soild #E6EAF0 !important;
}
.selectt{
  border-radius: 18px !important;
}

}
.setRow{
  background: #fff;
  border-radius: 0 0 20px 20px;
  border-top: 1px solid grey;
  margin: 0px;
  position: relative;
}
.form-control {
  border-radius: 18px !important;
}
.info_icon{
  cursor: pointer;
}
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  margin-top: -44px !important;
  width: 25px !important
}

.btn-info{
  background-color: #01c4c3 !important;  
  border-color: #01c4c3 !important;
}

.fa.fa-chevron-right.rotate.greaterArrow.clicked {
  transform: rotate(0deg) !important;
  left: 15px !important;
  transition: 0.3ms;
}