.cdk-global-overlay-wrapper, .cdk-overlay-container { z-index: 9999!important; } 

// .modal-content  {
//     position: relative;
//     display: -ms-flexbox;
//     display: flex;
//     -ms-flex-direction: column;
//     flex-direction: column;
//     width: 100%;
//     pointer-events: auto;
//     background-color: #ffffffe8;
//     background-clip: padding-box;
//     border: 1px solid rgba(12, 238, 249, 0.9);
//     box-shadow: 0px 1px 22px 2px rgba(255, 253, 253, 0.5);
//     filter: blur(0px);
//     border-radius: 7px;
// }

